import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { logoutUser } from "../actions";
import { appLogout, getAdminProfile } from "../thunks";
import { getMemberProfile } from "src/member/redux/thunks/profile";
import { ENUM_SUBSCRIPTION_STATUS } from "src/member/constants/enums";

type UserData = {
  authMethod: string;
  checkOutStatus: string;
  created_at: string;
  email: string;
  id: string;
  userId: string;
  subscription: keyof typeof ENUM_SUBSCRIPTION_STATUS;
  invitedBy: string | null;
  lastLogin: string;
  password: string;
  roleId: string;
  loginId: string;
  socialId: string | null;
  statusId: string | null;
  updated_at: string;
  userProfile: {
    addressId: string | null;
    created_at: string;
    firstName: string | null;
    firstTime: string | boolean;
    id: string;
    lastName: string;
    mobileNumber: string | null;
    profilePhoto: string | null;
    socialHandleId: string | null;
    subRoleId: string;
    updated_at: string;
    userId: string;
    userName: string;
    yourBio: string | null;
  };
};

type User = {
  isLoggedIn: boolean;
  name: string;
  data: UserData;
};

const initialState: User = {
  isLoggedIn: false,
  name: "",
  data: {
    id: "",
    userId: null,
    email: "",
    authMethod: "",
    password: "",
    socialId: null,
    roleId: "",
    subscription: ENUM_SUBSCRIPTION_STATUS.ACTIVE,
    statusId: null,
    invitedBy: null,
    loginId: null,
    lastLogin: "",
    checkOutStatus: "",
    created_at: "",
    updated_at: "",
    userProfile: {
      addressId: "",
      created_at: "",
      firstName: "",
      firstTime: "true",
      id: "",
      lastName: "",
      mobileNumber: "",
      profilePhoto: "",
      socialHandleId: "",
      subRoleId: "",
      updated_at: "",
      userId: "",
      userName: "",
      yourBio: "",
    },
  },
};

const userDetails = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state, action: PayloadAction<UserData>) => {
      state.isLoggedIn = true;
      state.name = "";
      state.data = { ...action.payload };
    },
    logout: (state) => {
      state.isLoggedIn = false;
      state.name = "";
      state.data = initialState.data;
      localStorage.clear();
    },
    setLoginDetails: (state, action) => {
      state.data.userId = action.payload.userId;
      state.data.email = action.payload.email;
      state.data.roleId = action.payload.roleId;
      state.data.subscription = action.payload.subscription;
      state.data.checkOutStatus = action.payload.checkOutStatus;
      state.data.loginId = action.payload.loginId;
    },
    setSubscriptionStatus: (state, action) => {
      state.data.subscription = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUser, (state, _action) => {
      state = initialState;
    });
    builder.addCase(appLogout.fulfilled, (state, _action) => {
      state.isLoggedIn = false;
      state.name = "";
      state.data = initialState.data;
      localStorage.clear();
    });
    builder.addCase(getAdminProfile.fulfilled, (state, action) => {
      state.isLoggedIn = true;
      state.name = "";
    });
    builder.addCase(getMemberProfile.fulfilled, (state, action) => {
      state.isLoggedIn = true;
      state.name = "";
    });
  },
});

export const { logout, login, setLoginDetails, setSubscriptionStatus } =
  userDetails.actions;
export default userDetails.reducer;
