import {
  AdminProfile,
  AdminRole,
  GetMemberOffers,
  GetMembersList,
  MemberListData,
  GetTransactionsList,
  MemberStatus,
} from "src/admin/types/api";
import dataProvider from "src/shared/dataProvider";

let fetcher = dataProvider("adminInstance");

export const adminProfileView = async () => {
  return await fetcher.get<{
    adminProfile: AdminProfile;
    adminRole: AdminRole;
  }>(`/admin`);
};
export const adminProfileUpdate = async (data: any) => {
  return await fetcher.put(`/admin`, data, {}, "v1", {});
};

export const getMemberProfile = async (id: string) => {
  return await fetcher.get(`/user/${id}?raw=true`);
};

export const getPersonalProfile = async (id: string) => {
  return await fetcher.get(`/user/${id}`);
};

export const appLogoutApi = async () => {
  return await fetcher.get(`/user/logout`);
};

export const changeStatusApi = async (data: MemberStatus) => {
  return await fetcher.post(`/user/change-status`, data);
};

export const getMembersList = async (queryParams: GetMembersList) => {
  return await fetcher.get<MemberListData[]>(`/user`, queryParams);
};

export const getMemberOffers = async (id: string, queryParams) => {
  return await fetcher.get<GetMemberOffers[]>(`/offer/${id}`, queryParams);
};

export const verifyCurrentPassword = async (data: {
  email: string;
  currentPassword: string;
}) => {
  return await fetcher.post(`/user/verify-password`, data);
};

export const getOfferDetails = async (id: string) => {
  return await fetcher.get(`/offer/details/${id}`);
};

export const changeMemberOfferStatus = async (data: {
  reason?: string;
  sendReason?: string;
  offerId: string;
  isResolved: boolean;
  userComment?: string;
  user?: string;
}) => {
  return await fetcher.patch(`/offer`, { ...data, user: "ADMIN" });
};
export const getMembershipDetails = async (id: string) => {
  return await fetcher.get(`/subscription/${id}`);
};
export const membershipDetailsById = async (id: string) => {
  return await fetcher.get(`/user/memberShip-details/${id}`);
};

export const memberDisableOfferList = async (id: string, queryParams) => {
  return await fetcher.get(`/offer/${id}?offerType=DISABLED`, queryParams);
};

export const memberInviteList = async (queryParams) => {
  return await fetcher.get(`/referral`, queryParams);
};

export const declineRequest = async (queryParams) => {
  return await fetcher.post(`/offer/decline-request`, queryParams);
};

export const getTransactions = async (queryParams: GetTransactionsList) => {
  return await fetcher.get(`/wallet/transactions`, queryParams);
};

export const getOfferActionSummary = async (id: string) => {
  return await fetcher.get(`/offer/action-summary/${id}`);
};

export const postTransactionApi = async (data: any) => {
  return await fetcher.post(`/wallet/transaction`, data);
};

export const searchUsers = async (queryParams) => {
  return await fetcher.get(`/user/user-name`, queryParams);
};

export const getReportedOffers = async (queryParams) => {
  return await fetcher.get(`/offer/report`, queryParams);
};

export const getReviewList = async (queryParams) => {
  return await fetcher.get(`/review/reported`, queryParams);
};

export const getReviewDetails = async (id: string) => {
  return await fetcher.get(`/review/reported/${id}`);
};

export const getReviewActionSummary = async (id: string) => {
  return await fetcher.get(`/review/${id}/action-summary`);
};

export const getReportedOfferDetails = async (offerId: string) => {
  return await fetcher.get(`/offer/${offerId}/reported`);
};

export const reviewReportStatus = async (data: {
  reviewId: string;
  reviewStatus: string;
  internalReason: string;
}) => {
  return await fetcher.post(`/review/report/enable-disable`, data);
};

export const getFeedbackList = async (queryParams) => {
  return await fetcher.get(`/feedback`, queryParams);
};

export const addAdminNotes = async (data) => {
  return await fetcher.post(`/feedback/admin`, data);
};

export const editAdminNotes = async (data) => {
  return await fetcher.patch(`/feedback/admin`, data);
};

export const exportAnalytics = async (queryParams) => {
  return await fetcher.get(`/analytics`, queryParams);
};

export const dashboardStats = async () => {
  return await fetcher.get(`/analytics/stats`);
};

export const membersReviews = async (queryParams) => {
  return await fetcher.get(`/review`, queryParams);
};

export const membersReviewReplies = async (id: string) => {
  return await fetcher.get(`/review/${id}/reply`);
};

export const getTransactionDetails = async (id: string) => {
  return await fetcher.get(`/wallet/transaction/${id}`);
};

export const reactionData = async ({
  id,
  status,
}: {
  id: string;
  status: string;
}) => {
  return await fetcher.get(
    `/review/${id}/like-dislike-history?likeOrDislike=${status}&offset=0&limit=10000000`,
  );
};
